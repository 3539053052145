.login-account-creation {
    padding-top: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #F7F7F7;
    height: 100vh;

    .login-content-wrapper {
        width: 655px;
        text-align: center;

        .new-to-hommer {
            font-size: 16px;
            font-weight: bold;
            font-family: $font-secondary;
            letter-spacing: 0.5px;
            margin-bottom: 5px;
            margin-top: 25px;
            text-align: center;
        }

        .input-area{
            box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.07);
            background-color: rgb(255, 255, 255);
            border-radius: 15px;
            padding: 44px 122px;
            width: 655px;
            margin: 0;

            .input-wrapper {
                margin-bottom: 14px;
                margin-top: 0;
            }

            h4 {
                font-weight: 500;
                font-family: $font-secondary;
                letter-spacing: 0.5px;
                color: $brand-dark-grey;
                margin-bottom: 22px;

            }

            .subheader {
                font-size: 16px;
                letter-spacing: 0px;
                color: $subheader-grey;
                margin-bottom: 38px;
            }

            button.btn-blue:disabled {
                background-color: $border-dark-grey;
                border-color: $border-dark-grey;
            }

            .forgot-password {
                font-size: 14px;
                font-weight: bold;
                font-family: $font-secondary;
                letter-spacing: 0.5px;

                margin-bottom: 10px;
                margin-top: 0px;

                a {
                    color: $brand-dark-grey;
                    float: right;
                }
            }

            input {
                font-family: $font-primary;
                vertical-align: middle;
                letter-spacing: 0.5px;
            }

            .error-message.has-error{
                padding: 5px 0px;
            }
        }
    }

    @media #{$screen-se-max} {
        padding-top: 24px;

        .login-content-wrapper {
             width: 290px;

             .input-area{
                width: 290px;
            }
        }
    }

    @media #{$screen-sm-max} {
        padding-top: 24px;

        .login-content-wrapper h4 {
            font-size: 24px;
        }
    }

    @media #{$screen-xs-max} {
        padding-top: 24px;
    }

    @media #{$screen-xxs-max} {
        padding-top: 24px;

        .login-content-wrapper {
            width: 100%;

            .input-area{
                width: 100%;
                padding: 44px 20px;
            }

            h4 {
                font-size: 20px;
                margin-bottom: 16px;
            }

            .subheader {
                font-size: 15px;
                margin-bottom: 6px;
            }
        }
    }
}
