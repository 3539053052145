@media #{$screen-xxs-max} {
    .hidden-xxs-max { display: none; }
    .visible-xxs-max { display: block !important; }
}

@media #{$screen-xs-max} {
    .hidden-xs-max { display: none; }
}

@media #{$screen-xs-min} {
    .hidden-xs-min { display: none; }
}

@media #{$screen-sm-min} {
    .hidden-sm-min { display: none; }
}

@media #{$screen-xs-ht-max} and (max-width: 750px) {
    .hidden-ht-max { display: none; }
}

.safari-ctr-hv {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
}