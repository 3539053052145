@import '../../../../public/static/sass/global/_vars.scss';

.banner-position {
    width: 100%;
    z-index: 100;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-container {
    font-size: 14px;
    cursor: pointer;
    &.text-only {
        cursor: default;
    }

    img {
        margin: 0 10px 0 0;
    }

    .banner-text {
        display: flex;

        .banner-copy {
            font-family: $font-primary;
            letter-spacing: 0px;
            font-weight: 500;
        }

        .banner-cta {
            margin-left: 14px;
            display: flex;
            font-weight: bold;
            letter-spacing: 0.5px;

            .btn-arrow {
                margin-left: 10px;
                margin-right: 4px;
            }

            &:hover {
                .btn-arrow {
                    margin-left: 14px;
                    margin-right: 0px;
                }
            }
        }
    }
}

.banner-close-button {
    position: absolute;
    right: 20px;
    font-size: 20px;
    cursor: pointer;

    @media #{$screen-sm-max} {
        right: 15px;
    }
}
