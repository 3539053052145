.modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 10000;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    &.big-modal {
        justify-content: top;

        .modal-bg {
            position: absolute;
            top: 20px;
            margin-bottom: 20px;

            @media #{$screen-xxs-max}, #{$screen-xs-ht-max} {
                position: relative;
                top: 0;
                margin-bottom: 0;
            }
        }
    }

    .modal-bg {
        border-radius: 8px;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
        position: relative;

        @media #{$screen-xxs-max}, #{$screen-xs-ht-max} {
            overflow-y: auto;
        }
    }
}

.close-icon {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;

    img {
        width: 40px;
        height: 40px;
    }
}
