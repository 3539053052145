@import '../../../../public/static/sass/global/_vars.scss';
@import '../../../../public/static/sass/global/_mixins.scss';

// breakpoint to hide header links and cta and show hamburger menu
$screen-menu-max: "screen and (max-width: 1090px)";

.header-area {
  z-index: 1300;
  top: 0;
  width: 100%;

  .header-thin {
    @media #{$screen-sm-max}, #{$screen-xs-ht-max} {
      display: none;
      --header-height: 0;
    }
  }
}

.header {
  min-height: var(--header-height);
  display: flex;

  a {
    &:active, &:hover, &.active {
      font-weight: 700;
    }
  }

  .header-links-container {
    margin-left: 55px;
    height: 100%;

    .sub-header-link {
      display: list-item;
      font-size: 13px;
      letter-spacing: 0.5px;
      font-weight: normal;
      white-space: nowrap;

      &:last-child {
        border-radius: 0 0 10px 10px;
      }

      &:hover, &:active, &.active {
        font-weight: bold;
        cursor: pointer;
        background: #F7F7F7;
      }

      &:active, &.active {
        font-weight: bold;
      }
    }

    .header-links{
	    height: 100%;
        display: flex;
	    padding-left: 20px;

	    ul{
            align-items: center;
            display: flex;

            .header-sub-menu {
                 display: flex;
                 height: 100%;
                 position: relative;
                 flex-direction: column;

                &:hover {
                    font-weight: bold;
                }

                &:hover .header-child-links {
                    display: block !important;
                    ul {
                        margin-top: 1px;
                        display: block !important;
                    }
                }
             }

            .header-link {
              font-size: 14px;
              display: inline;
              margin-right: 42px;
              cursor: pointer;
              height: 100%;
              display: flex;
              align-items: center;

              @media screen and (max-width: 900px) {
                margin-right: 20px;
                text-align: center;
                line-height: 18px;
              }
            }
        }
    }

    @media #{$screen-menu-max} {
      display: none;
    }
  }

  .btn-blue-border {
   height: 44px;
   font-size: 16px;
   letter-spacing: 1px;

    &:hover {
        background-color: #EEEEEE;
    }

    &:active {
        background-color: $brand-blue;
        color: #ffffff;
    }

    @media #{$screen-sm-max} {
      height: 36px;
      font-size: 12px;
      letter-spacing: 0.8px;
  }
}

  &.header-thin {
    .header-container {
      display: flex;
      justify-content: center;
    }
  }

  .header-container {
    flex: 1 1;
    padding: 0 60px 0 50px;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);

    @media #{$screen-sm-max} {
      padding: 0 40px 0 30px;
    }

    @media screen and (max-width: 700px) {
      padding: 0 25px;
    }

    .top-header {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        // NOTE: JSchlechte; This margin was added by Alejandra when working on CTA dropdowns. I cannot imagine why we
        //       would actually want to move the HOMER logo out of center; so I will attempt to remove this completely.
        // margin-left: 20px;

        &.centered-logo {
            justify-content: center;
        }

        @media #{$screen-sm-max} {
            margin-left: 0;
        }

        .header-right {
            margin-left: auto;
            height: 100%;

            a {
            margin-right: 25px;

            @media #{$screen-sm-max} {
                margin-right: 30px;
            }
            }
        }

        .header-menu {
            display: none;
            height: 100%;

            @media #{$screen-menu-max} {
                display: block;
            }

            .menu-container {
                height: 100%;
                display: flex;

                .hamburger-btn {
                    margin: auto auto;
                }
            }
        }

        .header-buttons {
            @include hv-center;
            letter-spacing: 0.1px;
            font-size: 14px;
            min-width: 305px;
            height: 100%;

            @media #{$screen-menu-max} {
              display: none;
            }

            .header-sub-menu {
                position: relative;
                height: 100%;

                .header-child-links {
                    .sub-header-link {
                      font-size: 13px;
                    }
                }

                &:hover .header-child-links {

                    @media (min-width: #{$md-width}), (max-width:#{$sm-width}) {
                      display: block !important;
                    }

                    ul {
                        margin-top: 1px;
                        display: block !important;

                        .sub-header-link {

                            &:hover, &:active, &.active {
                                font-weight: bold;
                                cursor: pointer;
                                background: #F7F7F7;
                            }

                            &:last-child {
                                border-radius: 0 0 10px 10px;
                            }
                        }
                    }
                }
            }
        }
    }
  }
}
