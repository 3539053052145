.stack-links-footer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 200px;
    @media #{$screen-xxs-max} {
        justify-content: space-evenly;
        padding-bottom: 30px;
    }
    @media #{$screen-xs-max} {
        padding-bottom: 50px;

        &::after {
            content: '';
            width: 152px;
        }
    }
    @media #{$screen-md-max} {
        padding-left: 0;
    }
    @media #{$screen-lg-max} {
        padding-bottom: 24px;
    }
}

.stack-links-item{
    width: 149px;
    margin-right: 20px;
    margin-bottom: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    @media #{$screen-xxs-max} {
        width: 152px;
        margin: 5px 11px 32px 0;
    }
    @media #{$screen-xs-max} {
        width: 152px;
        margin-right: 0;
        line-height: 32px;
    }
}

.stack-links-title {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 18px;
}

.divider-footer{
    height: 0;
    border: 1px solid #E7E8EA;
}

.footer-section{
    margin: 0;
    padding: 56px 64px 50px;
    @media #{$screen-xxs-max} {
        padding: 32px 24px 28px;
    }
}

.homer-logo-section {
    display: none;
    @media #{$screen-lg-min} {
      display: inline-block;
    }
}
.link-item{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0;
    opacity: 1;
    &:hover{
        cursor: pointer;
    }
}

.horizontal-links-footer{
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    display: flex;
    align-items: stretch;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 40px;
    .kidsafe-banner-wrapper {
        flex-grow: 1;
        padding-left: 32px;
    }
    .horizontal-link {
        margin-bottom: 0;
    }
    @media #{$screen-xs-max-footer} {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        .kidsafe-banner-wrapper {
            order: 1;
            padding-left: 0;
            transform: translateX(-9px);
            padding-top: 18px;
        }
        .horizontal-link {
            margin-bottom: 18px;
        }
    }
}

.horizontal-link {
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;

    &:hover{
        cursor: pointer;
        text-decoration: underline;
    }

    .link-item {
        font-size: 12px;
        line-height: 20px;
    }
}

.copyright-company-small-screen {
    display: none;

    @media #{$screen-xs-max-footer} {
        display: block;
    }
}

.flex-item-centered {
    display:flex;
    align-items: center !important;
}

.copyright-company-large-screen {
    display: inline-block;

    @media #{$screen-xs-max-footer} {
        display: none;
    }
}

.copyright-company {
    color: #8E9091;
    line-height: 20px;
    letter-spacing: 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    white-space: nowrap;
}


.icon-links-footer {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 20px;
    margin-left: 16px;

    @media #{$screen-xs-max-footer} {
        margin-bottom: 35px;
        margin-left: 0;
    }
}
.icon-link-footer {
    opacity: 0.7;

    &:hover{
        opacity: 1;
        cursor: pointer;
    }
}
.footer-icon-link {
    margin-left: 36px;

    &:first-child {
        margin-left: 0;
    }
}
