button {
  border: none;
  border-width: 0;
  background-color: transparent;
  padding: 0;
  letter-spacing: 1px;
  cursor: pointer;

  &[data-loading=spinner] .loading-spinner{
    display: block;
  }

  .loading-spinner {
    display: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:focus {
    outline: none;
  }

  &.btn-solid-color {
    height: 54px;
    border-radius: 10px;
    font-size: 18px;
    letter-spacing: 1.3px;
    padding: 0 27px;
    border-width: 2px;
    border-style: solid;
    color: #ffffff;

    @media #{$screen-sm-max} {
      padding: 0 24px;
      font-size: 16px;
      letter-spacing: 1px;
      height: 48px;
    }
  }

  &.btn-blue-border {
    @extend .btn-solid-color;
    border-color: $brand-blue;
    color: $brand-blue;

    &:hover {
      background-color: #EEEEEE;
    }

    &:active {
      background-color: $brand-blue;
      color: #ffffff;
    }
  }

  &.btn-blue {
    @extend .btn-solid-color;
    background-color: $brand-blue;
    border-color: $brand-blue;
    font-family: $font-secondary;
    font-weight: 500;

    &:hover {
      background-color: #1E8BB6;
    }

    &:active {
      background-color: #0474A0;
    }

    &:disabled {
      background-color: #e7e8ea;
      border-color: #e7e8ea;
    }
  }

  &.fix-to-bottom {
    width: 100%;
    position: fixed;
    height: 60px !important;
    bottom: 0;
    left: 0;
    border-radius: 0;
    z-index: 1000;
    display: none;
    font-family: $font-secondary;

    @media #{$screen-xxs-max}, #{$screen-xs-ht-max} and (max-width: 750px) {
      display: block;
    }
  }
}
