@import './../_flexbox';

$sm-width: 768px;
$md-width: 1024px;

$screen-xs-min: "screen and (min-width: 480px)";
$screen-sm-min: "screen and (min-width: 769px)";
$screen-md-min: "screen and (min-width: 1024px)";
$screen-lg-min: "screen and (min-width: 1200px)";
$screen-xxs-max: "screen and (max-width: 480px)";
$screen-xs-max: "screen and (max-width: 768px)";
$screen-xs-max-footer: "screen and (max-width: 862px)";
$screen-sm-max: "screen and (max-width: 1024px)";
$screen-md-max: "screen and (max-width: 1200px)";
$screen-lg-max: "screen and (max-width: 1450px)";

$textColor: #2e3c54;

$lightBlue: #008BCE;
$teal: #19afd0;

$brand-light-blue: #6CC5EA;
$brand-blue: #057eae;
$brand-homer-blue: #014A9E;
$brand-homer-dark-blue: #012F74;
$brand-grey: #5a5a5a;
$brand-dark-grey: #53565a;
$brand-black: #333333;
$brand-dark-grey: #53565a;

$onboarding-grey: #979797;

$subheader-grey: #8e9091;
$border-grey: #e6e5e0;
$border-dark-grey: #d2d2d2;

$form-grey: #CCCCCC;
$form-error: #db2d25;

$font-primary: "Escalator v0.18.1 Text", Helvetica, Arial, Lucida, sans-serif;
$font-secondary: "Escalator v0.18.1 Text", Helvetica, Arial, Lucida, sans-serif;

$screen-xs-ht-max: "screen and (max-height: 450px)";
$screen-se-max: "screen and (max-width: 350px)";

body.DEBUG:before {
  bottom: 0;
  content: "screen-xxs";
  display: block;
  position: fixed;
  right:0;
  z-index: 999999;
  @media #{$screen-xs-min} { content: "screen-xs"; }
  @media #{$screen-sm-min} { content: "screen-sm"; }
  @media #{$screen-md-min} { content: "screen-md"; }
  @media #{$screen-lg-min} { content: "screen-lg"; }
}

@mixin imtooflexy($width) {
  @include flex-basis($width);
  max-width: $width;
  width: $width;
}

@media #{$screen-xxs-max} {
  .col-xxs-12 { @include imtooflexy(100%) }
  .hidden-xxs,
  .hidden-xxs-max { display: none; }
}
@media #{$screen-xs-max} {
  .hidden-xs-max { display: none; }
}
@media #{$screen-sm-max} {
  .hidden-sm-max { display: none; }
}
@media #{$screen-md-max} {
  .hidden-md-max { display: none; }
}
@media #{$screen-xs-min} {
  .hidden-xs-min { display: none; }
}
@media #{$screen-sm-min} {
  .hidden-sm-min { display: none; }
}
@media #{$screen-md-min} {
  .hidden-md-min { display: none; }
}
@media #{$screen-lg-min} {
  .hidden-lg-min { display: none; }
}
