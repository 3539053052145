* { box-sizing: border-box; }

html { height: 100%; }
body {
  color: #5A5A5A;
  font-size: 16px;
  font-weight: normal;
  height: 100%;
  width: 100%;
}

fieldset { border: 0; }

ol,ul { list-style: none; margin: 0; padding: 0; }
body.hidden-header #PageWrapper {
  background-position: center top;
  padding-top: 0;
}
#PageWrapper {
  height: 100%;
  margin: 0 auto;
  max-width: 2200px;
  position: relative;
  width: 100%;
  &.Home { padding-top: 0; }
  &.no-started #Header .btn.blue { display: none; }
  &.no-auth #Header auth-dropdown { display: none; }
}
#SinglePage { display: none; }
a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  p & {
    color: $teal;
  }
}

p,h1,h2,h3,h4,h5,h6 { margin-top: 0; }

img {
  max-width: 100%;
  .center-img { margin: 0 auto; }
}
#SinglePage, #Header .content {
  margin: 0 auto;
  max-width: 1440px;
}

footer {
  margin: 0 auto;
  max-width: none;
}

#Main .single_page {
  /* for generically bringing other pages into the single page app */
  margin: 70px auto 100px;
  width: 820px;
}

.DEBUG_ONLY { display: none; }
body.DEBUG {
  [data-metric-help] {
    position: relative;
    &:before {
      background: white;
      content: attr(data-metric-help);
      color: $textColor;
      font-size: 30px;
      position: absolute;
      border: 2px solid;
      top: 0;
      left: 0;
    }
  }
  .DEBUG_ONLY {
    border: 6px double;
    padding: 5px 10px;
    display: block;
  }
}

.googleconversion { display: none; }
.metric-log { display: none; }
