.module-section {
    &.horizontal {
        display: flex;
        width: 90%;
        margin: auto;
    }

    &.top-border {
        border-top: 4px solid #f3f3f3; /* This creates the border. Replace black with whatever color you want. */
    }

    @media #{$screen-sm-max} {
        &.horizontal {
            flex-direction: column;
        }

        &.top-border {
            border-top: none;
        }
    }
}