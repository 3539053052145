.subfooter-wrapper {
    width: 100%;
    bottom: 0;
    left: 0;
    color: $onboarding-grey;
    font-size: 14px;
    height: 25px;
    background-color: #FFFFFF;
    padding-top: 3px;
    margin: 0 0 -2.8125rem;

    &-fixed {
        position: fixed;
        margin: 0;
    }
}

.onboarding {
    display: flex;
    flex-direction: column;
    color: $brand-black;
    letter-spacing: normal;
    font-weight: 400;
    font-family: $font-primary;


    @media #{$screen-xs-max} and (max-height: 767px) {
        height: auto;
    }

    .step {
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        .step-container {
            width: 100%;

            @media screen and (max-height: 400px) {
                height: 100% !important;
            }
        }
    }

    @media #{$screen-sm-max}, #{$screen-xs-ht-max}{
        min-height: 0;
    }
}
