$max-width: 1200px;
$max-width__header: 980px;
$vertical-spacing: 50px;
$vertical-spacing__small: 20px;
$horizontal-spacing: 50px;
$horizontal-spacing__small: 20px;

%amazon-ftu-subtext {
    color: $subheader-grey;
    font-size: rem(20px);
    line-height: rem(32px);
    font-weight: normal;
    text-transform: none;
    letter-spacing: rem(1px);
}

%amazon-ftu-setup__page-width-padded {
    $full-width: $max-width + ($horizontal-spacing * 2);
    box-sizing: border-box;
    padding-right: rem($horizontal-spacing);
    padding-left: rem($horizontal-spacing);
    max-width: rem($full-width);
}

.amazon-ftu-setup__page-width {
    max-width: rem($max-width);
    margin: 0 auto;
}

.amazon-ftu-setup__text-width {
    text-align: center;
    max-width: rem($max-width__header);
    box-sizing: border-box;
    padding: 0 rem($horizontal-spacing);
    margin: 0 auto;
}

.amazon-ftu-header {
    margin-top: rem(88px);
    margin-bottom: rem(80px);
}

.amazon-ftu-header__title {
    font-size: rem(32px);
    line-height: rem(42px);
}

.amazon-ftu-header__body {
    @extend %amazon-ftu-subtext;
}

.amazon-ftu-divider-container {
    @extend %amazon-ftu-setup__page-width-padded;
}

.amazon-ftu-divider {
    border: 0;
    border-top: 1px solid $border-grey;
}

.amazon-ftu-steps {
    $vertical-spacing-local: $vertical-spacing + 20px;
    @extend %amazon-ftu-setup__page-width-padded;
    padding-top: rem($vertical-spacing-local);
    padding-bottom: rem($vertical-spacing-local);
}

.amazon-ftu-footer {
    @extend %amazon-ftu-subtext;
    margin-bottom: rem(88px);

    a {
        color: $brand-blue;
        text-decoration: underline;
    }
}

.image-with-description {
    width: 100%;
    display: flex;

    &+.image-with-description {
        margin-top: rem($vertical-spacing);
    }

    .description-container {
        width: 50%;
        padding-right: rem($horizontal-spacing);
        box-sizing: border-box;
    }

    .title {
        color: $brand-blue;
        margin: rem(20px) 0 0;
    }

    .description {
        margin-top: rem(10px);
        line-height: rem(25px);
    }

    .image-container {
        width: 50%;

        .image {
            width: 100%;
            height: auto;
            border-radius: rem(10px);
        }
    }
}

@media all and (max-width: 1000px) {
    .amazon-ftu-header__title {
        font-size: rem(24px);
        line-height: rem(35px);
    }

    %amazon-ftu-subtext {
        font-size: rem(16px);
        line-height: rem(26px);
    }
}

@media all and (max-width: 650px) {
    .amazon-ftu-header {
        margin-top: rem(50px);
        margin-bottom: rem($vertical-spacing__small);
    }

    .amazon-ftu-setup__text-width {
        padding: 0 rem($horizontal-spacing__small);
    }

    .amazon-ftu-divider-container {
        padding: 0 rem($horizontal-spacing__small);
    }

    .amazon-ftu-steps {
        padding:
            rem($vertical-spacing__small)
            rem($horizontal-spacing__small)
            rem($vertical-spacing);
    }

    .amazon-ftu-footer {
        margin-bottom: rem($vertical-spacing__small);
    }

    .image-with-description {
        flex-direction: column;
        flex-flow: column-reverse;

        &+.image-with-description {
            margin-top: rem(30px);
        }

        .description-container {
            padding: 0;
            width: 100%;

            .title {
                margin-top: rem(10px);
            }
        }

        .image-container {
            width: 100%;
        }
    }
}

@media all and (max-width: 400px) {
    .amazon-ftu-header__title {
        font-size: rem(18px);
        line-height: rem(25px);
    }

    %amazon-ftu-subtext {
        font-size: rem(14px);
        line-height: rem(22px);
    }
}
