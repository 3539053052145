@mixin h-center {
    display: flex;
    align-items: center;
    flex-direction: column;
}

@mixin v-center {
    display: flex;
    justify-content: center;
}

@mixin hv-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin align-columns-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@mixin align-columns-left {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@mixin imgRetina($image, $extension, $width, $height, $params) {
    background: url($image + '.' + $extension) $params;
         
    @media 
    screen and (-webkit-min-device-pixel-ratio: 2),
    screen and (   min--moz-device-pixel-ratio: 2),
    screen and (   -moz-min-device-pixel-ratio: 2),
    screen and (     -o-min-device-pixel-ratio: 2/1),
    screen and (        min-device-pixel-ratio: 2),
    screen and (             min-resolution: 192dpi), 
    screen and (             min-resolution: 2dppx) {
       background: url($image + '@2x' + '.' + $extension) $params;
       background-size: $width $height;
    }
}

@mixin responsiveFontSize($min_width, $max_width, $min_font, $max_font) {
    font-size: #{$min_font}px;
    @media (min-width: #{$min_width}px) and (max-width: #{$max_width}px) {
        font-size: calc(#{$min_font}px + (#{$max_font} - #{$min_font}) * ( (100vw - #{$min_width}px) / ( #{$max_width} - #{$min_width})));
    }
    @media (min-width: #{$max_width}px) {
        font-size: #{$max_font}px;
    }
}