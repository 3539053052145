body {
    color: $brand-grey;
    font-family: $font-secondary;
    letter-spacing: 1px;
    font-weight: 500;
}

section {
    width: 100%;        
    padding: 0;
}

h1 {
    font-size: 48px;
    line-height: 1.21;
    font-weight: 500;

    @media #{$screen-md-max} {
        font-size: 36px;
    }

    @media #{$screen-sm-max} {
        font-size: 32px;
    }

    @media #{$screen-xxs-max} {
        font-size: 24px;
    }

    + .subheader {
        font-size: 22px;
        line-height: 1.36;
        font-family: $font-primary;
        margin-block-end: 0.83em;

        @media #{$screen-md-max} {
            font-size: 18px;
        }
    
        @media #{$screen-sm-max} {
            font-size: 16px;
            line-height: 1.38;
            letter-spacing: 0.5px;
        }
    
        @media #{$screen-xxs-max} {
            font-size: 14px;
            line-height: 1.29;
        }
    }
}

h2 {
    text-transform: uppercase;
    font-size: 2.5em;
    letter-spacing: 1.5px;    
}

h3 {
    font-size: 30px;
    line-height: 1.25;
    font-weight: 400;
    margin-bottom: .67em;

    &.h3-small {
        font-size: 24px;
        margin-block-end: 0.83em
    }

    + .subheader {
        font-family: $font-primary;
        font-size: 18px;
        font-weight: 100;
        line-height: 1.5;
        letter-spacing: 0.5px;
        color: $subheader-grey;
    }

    @media #{$screen-sm-max} {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.33;
        letter-spacing: 0.5px;

        &.h3-small {
            font-size: 16px;
            margin-block-end: 1.2em
        }

        + .subheader {
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: 0.3px;
        }
    }

    @media #{$screen-xs-max} {
        &.h3-small {
            margin-block-end: 1.3em
        }
    }

    @media #{$screen-xxs-max} {
        font-size: 18px;
        line-height: 1.11;
        letter-spacing: normal;

        + .subheader {
            font-size: 12px;
            line-height: 1.5;
            letter-spacing: 0.5px;
        }
    }        
}

h4 {
    font-size: 24px;
    margin-bottom: 45px;

    @media #{$screen-sm-max} {
        font-size: 20px;
        letter-spacing: 0.5px;
    }

    @media #{$screen-xs-max} {
        font-size: 16px;
    }

    .subheader {
        font-family: $font-primary;
        margin-top: 20px;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.56;
        letter-spacing: 0.5px;
        margin-block-end: 0.83em;
    
        @media #{$screen-xs-max} {
            font-size: 14px;
            line-height: 1.29;
            letter-spacing: 1px;
        }
    
        @media #{$screen-xxs-max} {
            margin-top: 10px;
        }
    }
}

h5 {
    font-size: 20px;
    margin-bottom: 0;
    font-family: $font-primary;
    font-weight: 500;

    @media #{$screen-sm-max} {
        font-size: 16px;
        letter-spacing: 0.8px;
    }

    @media #{$screen-xxs-max} {
        font-size: 14px;
        letter-spacing: 0.7px;
    }
}



h6 {
    font-weight: normal;
    font-size: 14px;

    @media #{$screen-sm-max} {
        font-size: 10px;
        letter-spacing: 0.5px;
	}
}