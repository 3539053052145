#HomerSite {
  --header-height: 72px;
  --banner-height: 0px;
  --mobile-breadcrumbs-height: 56px;
  --mobile-breadcrumbs-top: var(--header-height);
  --mobile-breadcrumbs-bottom: calc(var(--mobile-breadcrumbs-top) + var(--mobile-breadcrumbs-height));

  .header.header-thin {
    --header-height: 80px;
  }

  @media #{$screen-sm-max} {
    --header-height: 62px;
  }

  @media #{$screen-xxs-max} {
    --header-height: 48px;
  }
}

.main-content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  padding-top: calc(var(--header-height) + var(--banner-height));
}

/* Firefox.... */
:-moz-ui-invalid {
  box-shadow: none !important;
}
