@import '../../../../public/static/sass/global/_vars.scss';
@import '../../../../public/static/sass/global/_mixins.scss';

.menu-container {
    height: 40px;
    overflow: hidden;

    .hamburger-btn .hamburger {
        @include align-columns-center;
        width: 30px;

        div {
            width: 100%;
            margin: 2px 0;
            border-radius: 4px;
            border: 2px solid #d2d2d2;
        }
    }

    .menu-list-container {
        height: 100vh;
        width: 100%;
        border: 1px solid #E6E5E0;
        background: #ffffff;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        letter-spacing: 0.1px;
        color: $brand-grey;
        font-size: 22px;
        z-index: 102; // one above marketing banner

        .menu-list {
            height: calc(100% - 130px);
            overflow-y: scroll;
            letter-spacing:1px;
            clear: right;
            padding-top: 7px;
            border-bottom: 25px;

            li {
                padding: 0 20px 0 24px;
                margin: 30px 0;
                cursor: pointer;
                font-weight: bold;
            }

            div {

                li {
                    &:hover {
                        font-weight: bold;
                    }
                }
                .sub-menu-list {
                    li{
                        margin: 0 0 0 0;
                        padding: 11px 37px 11px 32px;
                        font-weight: 500;
                        font-size: 20px;
                        letter-spacing: 0.5px
                    }

                    li:first-child{
                        padding-top: 21px;
                    }

                    li:last-child{
                        padding-bottom: 21px;
                    }
                }
            }

            .bottom-menu {
                padding: 0px 24px 24px 24px;

                li {
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    color: #8E9091;
                    padding: 5px 0 5px 0;
                    margin: 7px 0;

                    a {
                        margin-right: 0px;
                        line-height: 30px;
                    }

                    &:last-child{
                        margin-bottom: 25px;
                    }
                }
            }

            .menu-arrow {
                display: inline-block;
                float: right;
                position: relative;
                height:20px;
                width:20px;
                margin-right: -3px;

                &.menu-arrow-down {
                  background: transparent url(../../../../public/static/img/buttons/dropdown.svg) center center/contain no-repeat;
                  background-size: 44px 44px;
                  top: 5px;
                }

                &.menu-arrow-up {
                  background: transparent url(../../../../public/static/img/buttons/dropdown.svg) center center/contain no-repeat;
                  transform: rotate(180deg);
                  background-size: 44px 44px;
                  margin-bottom: -1px;
                  top: 5px;
                }
              }
        }



        .menu-close-btn {
            background: transparent url(../../../../public/static/img/close/close.svg) center center/contain no-repeat;
            background-size: 44px 44px;
            float: right;
            margin: 0px 16px 0px 0px;
            height: 58px;
            width: 22px;
            vertical-align: middle;
            display: inline-block;
        }

    }

    .menu-open{
        box-shadow: 2px 0 20px rgba(0,0,0,0.5);
        transform: translateX(0);
    }
}
